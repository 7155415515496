/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import Popup from "reactjs-popup";
import { exportOrderPayments, exportWalletFunding, getAllDepartments, getAllLocation, getAllProducts, getAllReports, getAllStaff } from "src/api";
import { allReport } from "src/assets/constants";
import { DownIcon } from "src/assets/svg";
import { CustomDataTable, CustomDatePicker, CustomPopup, CustomSelectInput, CustomTextInput, FilterByDate, FilterDateTrigger } from "src/components";
import { exportToExcel } from "src/assets/functions";
import { format } from "date-fns";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Formik } from "formik";
import * as Yup from "yup";

const Reports = () => {
	return (
		<Tabs>
			<TabList>
				<Tab>Generate Report</Tab>
				<Tab>Finances</Tab>
				<Tab>Reports</Tab>
			</TabList>
			<TabPanel>
				<GenerateReport />
			</TabPanel>
			<TabPanel>
				<FinanceReport />
			</TabPanel>
			<TabPanel>
				<SelectReport />
			</TabPanel>
		</Tabs>
	);
};

const GenerateReport = () => {
	const excelInitialValues = {
		start_date: format(new Date(), "yyyy-MM-dd"),
		end_date: format(new Date(), "yyyy-MM-dd"),
		department: "",
		department_id: "",
	};

	const salesInitialValues = {
		start_date: format(new Date(), "yyyy-MM-dd"),
		end_date: format(new Date(), "yyyy-MM-dd"),
	};

	const { data: allDepartment } = useQuery({
		queryKey: ["allDepartments"],
		queryFn: getAllDepartments,
		suspense: true,
	});

	const baseURL = process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_LOCAL_BASEURL}` : `${process.env.REACT_APP_PROD_BASEURL}`;

	return (
		<div className="grid grid-cols-2 pt-4">
			<div>
				<Formik
					initialValues={excelInitialValues}
					validationSchema={Yup.object().shape({
						start_date: Yup.string().required("Start date is required"),
						end_date: Yup.string().required("End date is required"),
						department: Yup.string().required("Department is required"),
						department_id: Yup.string().required("Department is required"),
					})}
					onSubmit={(values) => {
						window.open(
							`${baseURL}/auth/reports-trail?end_date=${values.end_date}&start_date=${values.start_date}&department=${values.department}&department_id=${values.department_id}`,
							"_blank"
						);
					}}
				>
					{({ values, setFieldValue, handleSubmit, handleChange }) => (
						<form onSubmit={handleSubmit} className="flex flex-col gap-5">
							<h3 className="mb-10">Item Movement by Department</h3>
							<CustomDatePicker
								label="Start Date"
								name="start_date"
								className="p-[7px] border border-lightGray rounded-md w-full text-[1.4rem] text-lightText font-medium"
								containerClassName="max-w-[500px]"
								dateFormat="dd MMM yyyy"
								selected={new Date(values.start_date ?? null)}
								onChange={(date) => setFieldValue("start_date", format(date, "yyyy-MM-dd"))}
							/>
							<CustomDatePicker
								label="End Date"
								name="end_date"
								className="p-[7px] border border-lightGray rounded-md w-full text-[1.4rem] text-lightText font-medium"
								containerClassName="max-w-[500px]"
								dateFormat="dd MMM yyyy"
								selected={new Date(values.end_date ?? null)}
								onChange={(date) => setFieldValue("end_date", format(date, "yyyy-MM-dd"))}
							/>
							<div className="max-w-[500px]">
								<CustomSelectInput
									label="Department"
									name="department_id"
									onChange={(event) => {
										const [id, ...name] = event.target.value.split("-");
										setFieldValue("department", name?.join("-"));
										setFieldValue("department_id", id);
									}}
									value={values.department_id?.toString()?.concat("-", values.department)}
									placeholder="Select Department"
									placeholderValue="-"
									options={allDepartment?.data?.map((department) => ({
										value: department?.id?.toString()?.concat("-", department?.name),
										label: department?.name,
									}))}
								/>
							</div>
							<button className="btn-primary w-fit">Generate</button>
						</form>
					)}
				</Formik>
			</div>
			<div>
				<Formik initialValues={salesInitialValues}>
					{({ values, setFieldValue, handleSubmit }) => (
						<form onSubmit={handleSubmit} className="flex flex-col gap-5">
							<h3 className="mb-10">Sales Report</h3>
							<CustomDatePicker
								label="Start Date"
								name="start_date"
								className="p-[7px] border border-lightGray rounded-md w-full text-[1.4rem] text-lightText font-medium"
								containerClassName="max-w-[500px]"
								dateFormat="dd MMM yyyy"
								selected={new Date(values.start_date ?? null)}
								onChange={(date) => setFieldValue("start_date", format(date, "yyyy-MM-dd"))}
							/>
							<CustomDatePicker
								label="End Date"
								name="end_date"
								className="p-[7px] border border-lightGray rounded-md w-full text-[1.4rem] text-lightText font-medium"
								containerClassName="max-w-[500px]"
								dateFormat="dd MMM yyyy"
								selected={new Date(values.end_date ?? null)}
								onChange={(date) => setFieldValue("end_date", format(date, "yyyy-MM-dd"))}
							/>
							<a
								href={`${baseURL}/auth/reports-sales?end_date=${values.end_date}&start_date=${values.start_date}`}
								className="btn-primary w-fit"
								target="_blank"
							>
								Generate
							</a>
						</form>
					)}
				</Formik>
			</div>
		</div>
	);
};

const SelectReport = () => {
	const [text, setText] = useState(allReport[0].name);
	const [textId, setTextId] = useState(allReport[0].id);
	const [storeText, setStoreText] = useState("All Store");
	const [storeId, setStoreId] = useState("");
	const [itemText, setItemText] = useState("All Items");
	const [itemId, setItemId] = useState("");
	const [departmentText, setDepartmentText] = useState("All Department");
	const [departmentId, setDepartmentId] = useState("");
	const [staffText, setStaffText] = useState("All Staff");
	const [staffId, setStaffId] = useState("");
	const [staffBtnIsDisabled, setStaffBtnIsDisabled] = useState(true);
	const [deptBtnIsDisabled, setDeptBtnIsDisabled] = useState(true);
	const [itemsBtnIsDisabled, setItemsBtnIsDisabled] = useState(false);
	const [storeBtnIsDisabled, setStoreBtnIsDisabled] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [tableColumn, setTableColumn] = useState([]);

	const startDate = searchParams.get("startDate");
	const endDate = searchParams.get("endDate");

	const columns = [
		{
			name: "S/N",
			selector: (_, index) => index + 1,
			compact: true,
			width: "10%",
		},
		{
			name: "Tag ID",
			selector: (row) => row?.item?.tagId || row?.tagId,
			style: { textTransform: "uppercase" },
			compact: true,
			width: "26.6%",
		},
		{
			name: "Item Type",
			selector: (row) => row?.product?.name || row?.name,
			style: { textTransform: "capitalize" },
			compact: true,
			width: "26.6%",
		},
		{
			name: "Order ID",
			selector: (row) => row?.order_id,
			style: { textTransform: "capitalize" },
			compact: true,
			width: "10%",
		},

		staffBtnIsDisabled
			? {
					name: "Store Name",
					selector: (row) => row?.location?.locationName,
					compact: true,
					width: "26.6%",
			  }
			: {
					name: "Staff Name",
					selector: (row) => row?.fullName,
					compact: true,
					width: "26.6%",
			  },
	];

	const garmentLeftFactoryColumn = [
		{
			name: "S/N",
			selector: (_, index) => index + 1,
			compact: true,
			width: "10%",
		},
		{
			name: "Order ID",
			selector: (row) => row?.order_id,
			style: { textTransform: "capitalize" },
			compact: true,
		},
		{
			name: "Number of Pieces",
			selector: (row) => row?.number_of_pieces,
			style: { textTransform: "capitalize" },
			compact: true,
		},
		{
			name: "Store",
			selector: (row) => row?.locationName,
			style: { textTransform: "capitalize" },
			compact: true,
		},
	];

	const garmentLeftDeptColumn = [
		{
			name: "S/N",
			selector: (_, index) => index + 1,
			compact: true,
			width: "10%",
		},
		textId === 2
			? {}
			: {
					name: "Item Type",
					selector: (row) => row?.product_type,
					style: { textTransform: "capitalize" },
					compact: true,
			  },
		{
			name: "Number of Pieces",
			selector: (row) => (textId === 2 ? row?.total : row?.number_of_items),
			style: { textTransform: "capitalize" },
			compact: true,
		},
		{
			name: "Department",
			selector: (row) => row?.department_name,
			style: { textTransform: "capitalize" },
			compact: true,
		},
	];

	const garmentForRewashColumn = [
		{
			name: "S/N",
			selector: (_, index) => index + 1,
			compact: true,
			width: "10%",
		},
		{
			name: "Item ID",
			selector: (row) => row?.item_id,
			style: { textTransform: "capitalize" },
			compact: true,
		},
		{
			name: "Item Type",
			selector: (row) => row?.item_name,
			style: { textTransform: "capitalize" },
			compact: true,
		},
		{
			name: "Department",
			selector: (row) => row?.department_name,
			style: { textTransform: "capitalize" },
			compact: true,
		},
		{
			name: "Date",
			selector: (row) => formattedDate(row?.date),
			style: { textTransform: "capitalize" },
			compact: true,
		},
	];

	const garmentRejectedColumn = [
		{
			name: "S/N",
			selector: (_, index) => index + 1,
			compact: true,
			width: "10%",
		},
		{
			name: "Item ID",
			selector: (row) => row?.item_id,
			style: { textTransform: "capitalize" },
			compact: true,
		},
		{
			name: "Item Type",
			selector: (row) => row?.item_name,
			style: { textTransform: "capitalize" },
			compact: true,
		},
		{
			name: "Order ID",
			selector: (row) => row?.order_id,
			style: { textTransform: "capitalize" },
			compact: true,
		},
		{
			name: "Total Number of Pieces",
			selector: (row) => row?.total_number_pieces,
			style: { textTransform: "capitalize" },
			compact: true,
		},
		{
			name: "Rejected From",
			selector: (row) => row?.from_department,
			style: { textTransform: "capitalize" },
			compact: true,
		},
	];
	const individualGarmentColumn = [
		{
			name: "S/N",
			selector: (_, index) => index + 1,
			compact: true,
			width: "10%",
		},
		{
			name: "Staff",
			selector: (row) => row?.staff_name,
			style: { textTransform: "capitalize" },
			compact: true,
		},
		{
			name: "Department",
			selector: (row) => row?.department,
			style: { textTransform: "capitalize" },
			compact: true,
		},
		{
			name: "Total Number of Pieces",
			selector: (row) => row?.number_of_pieces,
			style: { textTransform: "capitalize" },
			compact: true,
		},
		{
			name: "Date",
			selector: (row) => formattedDate(row?.["Date"]),
			style: { textTransform: "capitalize" },
			compact: true,
		},
	];

	//all Location
	const { data: allLocations } = useQuery({
		queryKey: ["allLocations"],
		queryFn: getAllLocation,
		suspense: true,
	});

	//all products
	const { data: allProducts } = useQuery({
		queryKey: ["allProduct"],
		queryFn: getAllProducts,
		suspense: true,
	});

	//all department
	const { data: allDepartment } = useQuery({
		queryKey: ["allDepartment"],
		queryFn: getAllDepartments,
		suspense: true,
	});

	//all staff
	const { data: allStaff } = useQuery({
		queryKey: ["allStaff"],
		queryFn: getAllStaff,
		suspense: true,
	});

	const formattedDate = (data) => {
		if (data) {
			return format(new Date(data), "do MMMM yyyy");
		}
	};

	//Get Items & Garments Per Location
	const {
		data: allReports,
		refetch,
		isFetching,
	} = useQuery({
		queryKey: ["allReports", textId, storeId, itemId, startDate, endDate, departmentId, staffId],
		queryFn: () => getAllReports({ id: textId, storeId, itemId, startDate, endDate, departmentId, staffId }),
	});

	const handleExcelExport = (data) => {
		var headers, excelData, count;

		switch (textId) {
			case 2:
				headers = ["S/N", "Number of Pieces", "Department"];
				excelData = [headers];
				count = 0;

				data.forEach((row) => {
					excelData.push([count + 1, row?.total || "", row?.department_name || ""]);
					count++;
				});
				break;
			case 4:
				headers = ["S/N", "Order ID", "Number of Pieces", "Store"];
				excelData = [headers];
				count = 0;

				data.forEach((row) => {
					excelData.push([count + 1, row?.order_id || "", row?.number_of_pieces || "", row?.locationName || ""]);
					count++;
				});
				break;
			case 5:
				headers = ["S/N", "Item Type", "Number of Pieces", "Department"];
				excelData = [headers];
				count = 0;

				data.forEach((row) => {
					excelData.push([count + 1, row?.product_type || "", row?.number_of_items || "", row?.department_name || ""]);
					count++;
				});
				break;
			case 6:
				headers = ["S/N", "Item ID", "Item Type", "Department", "Date"];
				excelData = [headers];
				count = 0;

				data.forEach((row) => {
					excelData.push([count + 1, row?.item_id || "", row?.item_name || "", row?.department_name || "", formattedDate(row?.date) || ""]);
					count++;
				});
				break;
			case 8:
				headers = ["S/N", "Item ID", "Item Type", "Order ID", "Total Number of Pieces", "Rejected From"];
				excelData = [headers];
				count = 0;

				data.forEach((row) => {
					excelData.push([
						count + 1,
						row?.item_id || "",
						row?.item_name || "",
						row?.order_id || "",
						row?.total_number_pieces || "",
						row?.from_department || "",
					]);
					count++;
				});
				break;
			case 9:
				headers = ["S/N", "Staff", "Department", "Total Number of Pieces", "Date"];
				excelData = [headers];
				count = 0;

				data.forEach((row) => {
					excelData.push([
						count + 1,
						row?.staff_name || "",
						row?.department || "",
						row?.number_of_pieces || "",
						formattedDate(row?.["Date"]) || "",
					]);
					count++;
				});
				break;
			default:
				headers = ["S/N", "Tag ID", "Item Type", "Order ID", "Store Name"];
				excelData = [headers];
				count = 0;

				data.forEach((row) => {
					excelData.push([
						count + 1,
						row?.item?.tagId || row?.tagId || "",
						row?.product?.name || row?.name || "",
						row?.order_id || "",
						row?.location?.locationName || "",
					]);
					count++;
				});
				break;
		}

		const today = new Date();
		const options = {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			hour12: false,
		};
		const formattedDate = today.toLocaleString("en-US", options).replace(",", "");
		const name = `Report(${formattedDate}).xlsx`;

		exportToExcel(excelData, name);
	};

	useEffect(() => {
		switch (textId) {
			case 1:
				setItemsBtnIsDisabled(false);
				setStoreBtnIsDisabled(false);
				setDeptBtnIsDisabled(true);
				setStaffBtnIsDisabled(true);
				break;

			case 2:
			case 5:
				setDeptBtnIsDisabled(false);
				setItemsBtnIsDisabled(true);
				setStoreBtnIsDisabled(true);
				setStaffBtnIsDisabled(true);
				break;

			case 4:
			case 6:
			case 8:
				setDeptBtnIsDisabled(true);
				setItemsBtnIsDisabled(true);
				setStoreBtnIsDisabled(true);
				setStaffBtnIsDisabled(true);
				break;

			case 9:
				setDeptBtnIsDisabled(true);
				setItemsBtnIsDisabled(true);
				setStoreBtnIsDisabled(true);
				setStaffBtnIsDisabled(false);
				break;

			default:
				setDeptBtnIsDisabled(false);
				setItemsBtnIsDisabled(false);
				setStoreBtnIsDisabled(false);
				setStaffBtnIsDisabled(false);
				break;
		}
	}, [textId]);

	useEffect(() => {
		switch (textId) {
			case 4:
				setTableColumn(garmentLeftFactoryColumn);
				break;
			case 2:
			case 5:
				setTableColumn(garmentLeftDeptColumn);
				break;
			case 6:
				setTableColumn(garmentForRewashColumn);
				break;
			case 8:
				setTableColumn(garmentRejectedColumn);
				break;
			case 9:
				setTableColumn(individualGarmentColumn);
				break;
			default:
				setTableColumn(columns);
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allReports]);

	return (
		<>
			<div className="border-b border-b-lightGray flex items-center justify-between py-[1.45rem]">
				<div className="inline-flex items-center gap-5">
					<div className="text-justify text-gray-600 text-[1.6rem] font-medium">Select Report</div>
					<CustomPopup
						trigger={
							<button
								type="button"
								className="flex min-w-[90px] bg-white items-center justify-center px-[1.5rem] py-[0.65rem] rounded-md border-lightText border cursor-pointer gap-2 text-[1.4rem] text-darkText"
							>
								{text} <DownIcon />
							</button>
						}
						arrow={false}
						closeOnDocumentClick
						position={["bottom left"]}
						width={"fit-content"}
						nested
						childrenArray={allReport?.map((report) => ({
							text: <div className="flex items-center gap-3">{report?.name}</div>,
							onClick: () => {
								setText(report?.name);
								setTextId(report?.id);
								setSearchParams({});
							},
						}))}
					/>
				</div>
			</div>
			<div id="top-area" className="min-h-[100vh]">
				<div className="flex items-center justify-between my-[4rem]">
					<div className="flex items-center justify-between gap-[3rem]">
						{/* store */}
						{!storeBtnIsDisabled && (
							<CustomPopup
								trigger={
									<button
										type="button"
										disabled={storeBtnIsDisabled}
										className="flex min-w-[90px] items-center justify-center px-[1.5rem] py-[0.65rem] rounded-md border-lightText border cursor-pointer gap-2 text-[1.4rem] text-darkText capitalize"
									>
										{storeText} <DownIcon />
									</button>
								}
								arrow={false}
								closeOnDocumentClick
								position={["bottom left"]}
								width={"fit-content"}
								nested
								childrenArray={[
									{
										text: <div className="flex items-center gap-3">{"All Store"}</div>,
										onClick: () => {
											setStoreText("All Store");
											setStoreId("");
										},
									},
									...allLocations?.data?.map((location) => ({
										text: (
											<div className="flex items-center gap-3 capitalize">
												{location?.locationName} {/* Use location.name instead of report?.name */}
											</div>
										),
										onClick: () => {
											setStoreText(location?.locationName);
											setStoreId(location?.id);
										},
									})),
								]}
							/>
						)}
						{/* item type */}
						{!itemsBtnIsDisabled && (
							<CustomPopup
								trigger={
									<button
										type="button"
										disabled={itemsBtnIsDisabled}
										className="flex min-w-[90px] items-center justify-around px-[1.5rem] py-[0.65rem] rounded-md border-lightText border cursor-pointer gap-2 text-[1.4rem] text-darkText capitalize"
									>
										{itemText} <DownIcon />
									</button>
								}
								arrow={false}
								closeOnDocumentClick
								position={["bottom left"]}
								width={"fit-content"}
								nested
								childrenArray={[
									{
										text: <div className="flex items-center gap-3">{"All Items"}</div>,
										onClick: () => {
											setItemText("All Items");
											setItemId("");
										},
									},
									...allProducts?.data?.products?.map((product) => ({
										text: <div className="flex items-center gap-3 capitalize">{product?.name}</div>,
										onClick: () => {
											setItemText(product?.name);
											setItemId(product?.id);
										},
									})),
								]}
							/>
						)}

						{/* Department type */}
						{!deptBtnIsDisabled && (
							<CustomPopup
								trigger={
									<button
										type="button"
										disabled={deptBtnIsDisabled}
										className="flex min-w-[90px] items-center justify-around px-[1.5rem] py-[0.65rem] rounded-md border-lightText border cursor-pointer gap-2 text-[1.4rem] text-darkText capitalize"
									>
										{departmentText} <DownIcon />
									</button>
								}
								arrow={false}
								closeOnDocumentClick
								position={["bottom left"]}
								width={"fit-content"}
								nested
								childrenArray={[
									{
										text: <div className="flex items-center gap-3">{"All Department"}</div>,
										onClick: () => {
											setDepartmentText("All Department");
											setDepartmentId();
										},
									},
									...allDepartment?.data?.map((department) => ({
										text: <div className="flex items-center gap-3 capitalize">{department?.name}</div>,
										onClick: () => {
											setDepartmentText(department?.name);
											setDepartmentId(department?.id);
										},
									})),
								]}
							/>
						)}

						{/* All Staff */}
						{!staffBtnIsDisabled && (
							<CustomPopup
								trigger={
									<button
										type="button"
										disabled={staffBtnIsDisabled}
										className="flex min-w-[90px] items-center justify-around px-[1.5rem] py-[0.65rem] rounded-md border-lightText border cursor-pointer gap-2 text-[1.4rem] text-darkText capitalize"
									>
										{staffText} <DownIcon />
									</button>
								}
								arrow={false}
								closeOnDocumentClick
								position={["bottom left"]}
								width={"fit-content"}
								nested
								childrenArray={[
									{
										text: <div className="flex items-center gap-3">{"All Staff"}</div>,
										onClick: () => {
											setStaffText("All Staff");
											setStaffId();
										},
									},
									...allStaff?.data?.map((staff) => ({
										text: <div className="flex items-center gap-3 capitalize">{staff?.fullName}</div>,
										onClick: () => {
											setStaffText(staff?.fullName);
											setStaffId(staff?.id);
										},
									})),
								]}
							/>
						)}

						<Popup
							trigger={<FilterDateTrigger name="Filter Date" />}
							arrow={false}
							closeOnDocumentClick
							className="date"
							position={["bottom right"]}
							keepTooltipInside="#top-area "
							contentStyle={{
								width: "fit-content",
								padding: 0,
							}}
						>
							<FilterByDate />
						</Popup>

						<button type="submit" className="btn-primary" onClick={() => refetch()} disabled={isFetching}>
							Filter
						</button>
					</div>
					<div className="flex gap-[3rem]">
						<div>
							<button className="btn-primary" disabled={isFetching} onClick={() => handleExcelExport(allReports?.data?.items)}>
								Export to Excel
							</button>
						</div>
					</div>
				</div>
				<CustomDataTable
					columns={tableColumn}
					data={allReports?.data?.items}
					paginationTotalRows={allReports?.data?.total}
					progressPending={isFetching}
					paginationServer={false}
				/>
			</div>
		</>
	);
};

const FinanceReport = () => {
	const orderPaymentInitialValues = {
		start_date: format(new Date(), "yyyy-MM-dd"),
		end_date: format(new Date(), "yyyy-MM-dd"),
		order_id: "",
	};

	const walletFundingInitialValues = {
		start_date: format(new Date(), "yyyy-MM-dd"),
		end_date: format(new Date(), "yyyy-MM-dd"),
	};

	const validationSchema = Yup.object().shape({
		start_date: Yup.string().required("Start date is required"),
		end_date: Yup.string().required("End date is required"),
	});

	return (
		<div className="grid grid-cols-2 pt-4">
			<div>
				<Formik
					initialValues={orderPaymentInitialValues}
					validationSchema={validationSchema}
					onSubmit={async (values) => {
						await exportOrderPayments(values);
					}}
				>
					{({ values, setFieldValue, handleSubmit, handleChange, isSubmitting }) => (
						<form onSubmit={handleSubmit} className="flex flex-col gap-5">
							<h3 className="mb-10">Order Payments</h3>
							<CustomDatePicker
								label="Start Date"
								name="start_date"
								className="p-[7px] border border-lightGray rounded-md w-full text-[1.4rem] text-lightText font-medium"
								containerClassName="max-w-[500px]"
								dateFormat="dd MMM yyyy"
								selected={new Date(values.start_date ?? null)}
								onChange={(date) => setFieldValue("start_date", format(date, "yyyy-MM-dd"))}
							/>
							<CustomDatePicker
								label="End Date"
								name="end_date"
								className="p-[7px] border border-lightGray rounded-md w-full text-[1.4rem] text-lightText font-medium"
								containerClassName="max-w-[500px]"
								dateFormat="dd MMM yyyy"
								selected={new Date(values.end_date ?? null)}
								onChange={(date) => setFieldValue("end_date", format(date, "yyyy-MM-dd"))}
							/>
							<div className="max-w-[500px]">
								<CustomTextInput
									label="Order Number"
									placeholder="Enter Order Number"
									className="p-[7px] border border-lightGray rounded-md w-full text-[1.4rem] text-lightText font-medium"
									name="order_id"
									value={values.order_id}
									onChange={handleChange}
								/>
							</div>
							<button className="btn-primary w-fit" disabled={isSubmitting}>
								Generate
							</button>
						</form>
					)}
				</Formik>
			</div>
			<div>
				<Formik
					validationSchema={validationSchema}
					initialValues={walletFundingInitialValues}
					onSubmit={async (values) => {
						await exportWalletFunding(values);
					}}
				>
					{({ values, setFieldValue, handleSubmit, isSubmitting }) => (
						<form onSubmit={handleSubmit} className="flex flex-col gap-5">
							<h3 className="mb-10">Wallet Funding</h3>
							<CustomDatePicker
								label="Start Date"
								name="start_date"
								className="p-[7px] border border-lightGray rounded-md w-full text-[1.4rem] text-lightText font-medium"
								containerClassName="max-w-[500px]"
								dateFormat="dd MMM yyyy"
								selected={new Date(values.start_date ?? null)}
								onChange={(date) => setFieldValue("start_date", format(date, "yyyy-MM-dd"))}
							/>
							<CustomDatePicker
								label="End Date"
								name="end_date"
								className="p-[7px] border border-lightGray rounded-md w-full text-[1.4rem] text-lightText font-medium"
								containerClassName="max-w-[500px]"
								dateFormat="dd MMM yyyy"
								selected={new Date(values.end_date ?? null)}
								onChange={(date) => setFieldValue("end_date", format(date, "yyyy-MM-dd"))}
							/>
							<button className="btn-primary w-fit" disabled={isSubmitting}>
								Generate
							</button>
						</form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default Reports;
