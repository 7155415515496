import { useQueries } from "@tanstack/react-query";
import { addDays, format } from "date-fns";
import { parse } from "date-fns";
import React from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import { useSearchParams } from "react-router-dom";
import Popup from "reactjs-popup";
import { exportProductGroupSaleMetrics, getProductNumbers } from "src/api";
import { renderCurrency, renderNumber } from "src/assets/functions";
import { FilterByDate, FilterDateTrigger } from "src/components";

const Products = () => {
	const [searchParams] = useSearchParams();
	const startDate = searchParams.get("startDate");
	const endDate = searchParams.get("endDate");

	const parsedStartDate = !!startDate ? parse(startDate, "yyyy-MM-dd", new Date()) : new Date();
	const parsedEndDate = !!endDate ? parse(endDate, "yyyy-MM-dd", new Date()) : addDays(parsedStartDate, 7);

	const formattedStartDate = format(parsedStartDate, "dd MMM yyyy");
	const formattedEndDate = format(parsedEndDate, "dd MMM yyyy");

	const [{ data: rawProductNumbers }] = useQueries({
		queries: [
			{
				queryKey: ["getProductNumbers", startDate, endDate],
				queryFn: () => getProductNumbers({ start_date: startDate, end_date: endDate }),
				suspense: true,
			},
		],
	});

	const productNumbers = rawProductNumbers?.data;

	return (
		<div className="grid px-[2.5rem] mt-[30px]">
			<div className="flex flex-wrap items-center gap-3 mb-[20px] ml-auto">
				<Popup
					trigger={<FilterDateTrigger name={searchParams.has("startDate") ? `${formattedStartDate} - ${formattedEndDate}` : "Filter"} />}
					arrow={false}
					closeOnDocumentClicks
					className="date"
					position={["bottom left"]}
					contentStyle={{
						width: "fit-content",
						padding: 0,
					}}
				>
					<FilterByDate />
				</Popup>
				<button
					onClick={() => exportProductGroupSaleMetrics({ start_date: startDate, end_date: endDate })}
					className="flex items-center btn-primary"
				>
					Export
				</button>
			</div>
			<div>
				<Accordion
					preExpanded={[productNumbers?.at(0)?.product_group_id]}
					allowMultipleExpanded
					allowZeroExpanded
					style={{ marginTop: 20, backgroundColor: "transparent", border: "none" }}
				>
					{productNumbers?.map((productGroup) => (
						<AccordionItem uuid={productGroup?.product_group_id} key={productGroup?.product_group_id} className="mb-10 bg-white">
							<AccordionItemHeading className="overflow-hidden rounded-lg">
								<AccordionItemButton className="accordion__button !bg-gray-200">
									{productGroup?.product_group_name} ({renderNumber(productGroup?.total_transaction_count)})
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel style={{ paddingInline: 30 }}>
								<div className="grid gap-x-[40px] gap-y-[40px] grid-cols-[repeat(auto-fit,_minmax(300px,_1fr))]">
									{productGroup?.products?.map((product) => (
										<div key={product?.product_id}>
											<h3 className={`text-[1.8rem] capitalize font-semibold mb-4`}>
												{product?.product_name} ({renderNumber(product?.total_transaction_count)})
											</h3>
											{product?.product_options?.map((option) => (
												<div key={option?.product_option_id} className="mb-6">
													<div className={"flex items-center justify-between"}>
														<p className={Boolean(option?.isDeleted) && "line-through"}>{option?.product_option_name}</p>
														<p>{renderNumber(option?.transaction_count)}</p>
													</div>
													<ul className="list-disc list-inside">
														<li className="flex items-center justify-between">
															<span>Express</span>
															<span className="font-bold">
																{renderCurrency(
																	option.express_transaction_count * option.product_option_expressPrice
																)}
															</span>
														</li>
														<li className="flex items-center justify-between">
															<span>Non-Express</span>
															<span className="font-bold">
																{renderCurrency(option.non_express_transaction_count * option.product_option_price)}
															</span>
														</li>
														<li className="flex items-center justify-between">
															<span>Custom</span>
															<span className="font-bold">{renderCurrency(option.custom_price_total)}</span>
														</li>
													</ul>
												</div>
											))}
										</div>
									))}
								</div>
							</AccordionItemPanel>
						</AccordionItem>
					))}
				</Accordion>
			</div>
			{/* <div>
				{productNumbers?.map((productGroup) => (
					<div key={productGroup?.product_group_id} className="mb-[70px] border border-gray-300 px-8 py-8 rounded-lg">
						<h2 className="text-[2rem] capitalize mb-4 font-bold">
							{productGroup?.product_group_name} ({productGroup?.total_transaction_count})
						</h2>
						<div className="grid gap-[25px] grid-cols-[repeat(auto-fit,_minmax(300px,_1fr))]">
							{productGroup?.products?.map((product) => (
								<div key={product?.product_id}>
									<h3 className="text-[1.8rem] capitalize font-semibold">
										{product?.product_name} ({product?.total_transaction_count})
									</h3>
									{product?.product_options?.map((option) => (
										<div key={option?.product_option_id} className="flex items-center justify-between">
											<p>{option?.product_option_name}</p>
											<p>{option?.transaction_count}</p>
										</div>
									))}
								</div>
							))}
						</div>
					</div>
				))}
			</div> */}
		</div>
	);
};

export default Products;
